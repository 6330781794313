<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="q-pa-none">
			<div class="text-h6">Filter Reports</div>
		</q-card-section>
		<q-card-section class="q-pa-none">
			<FilterDate
				:is_activity_log='false'
				@resetDate="resetDate"
				@dateFromSelected="dateFromSelected"
				@dateToSelected="dateToSelected"
			/>
			<FilterByFieldList
				@changeFieldValue="changeFieldValue"
			/>
		</q-card-section>
		<q-card-actions align="right" class="bg-white text-primary q-pa-none">
			<q-btn flat label="Cancel" @click="$emit('close')" />
			<q-btn flat label="Apply filters" @click="listIncidents()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import FilterDate from "@/components/utils/FiltersAndOrdering/FilterDate.vue";
import FilterByFieldList from "@/components/incidents/reports/FilterByFieldList.vue";
export default {
	name: "FiltersAndSort",
	components: {
		FilterDate,
		FilterByFieldList,
	},
	data (){
		return {
			date_from: "",
			date_to: "",
			field_value: "",
		}
	},
	methods: {
		async resetDate(){
			this.date_from = "";
			this.date_to = "";
		},
		async dateFromSelected(date){
			this.date_from = date;
			if(this.date_from > this.date_to){
				this.date_to = "";
			}
		},
		async dateToSelected(date){
			this.date_to = date;
		},
		async changeFieldValue(value){
			this.field_value = value.map(val => (val.value));
		},
		async listIncidents(){
			this.$emit('update-filters', {
				"date_from": this.date_from,
				"date_to": this.date_to,
				"field_value": this.field_value
			});
			this.$emit('close')
		},
	},
};
</script>
