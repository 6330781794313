<script>
import { Doughnut } from 'vue-chartjs'
import { mapActions, mapGetters } from "vuex";
export default {
	extends: Doughnut,
	data () {
		return {
			chartData: {
				labels: [],
				datasets: [{
					data: [],
				}],
			},
			options: {
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						usePointStyle: true,
						PointStyle: 'circle',
					},
					onClick: (e, item) => {
						return false;
					}
				},
				animation: false,
				onClick: this.filter,
			}
		}
	},
	computed: {
		...mapGetters(["incidents"]),
	},
	methods:{
		...mapActions(["listIncidents"]),
		async createGraphData(incidents){
			let parsed_incidents = JSON.parse(JSON.stringify(incidents))
			let labels_list = []
			let count_state = {}
			let background_color = []
			let hover_color = []

			for (let i = 0; i < parsed_incidents.length; i++) {
				labels_list.push(parsed_incidents[i].state)
			}

			for (const element of labels_list) {
				if (count_state[element]) {
					count_state[element] += 1;
				} else {
					count_state[element] = 1;
				}
			}

			count_state = this.sortOnKeys(count_state);
			for (let item in count_state ){
				if (item == 'active'){
					background_color.push('#996E00')
					hover_color.push('#A87900')
				}else if(item == 'finished'){
					background_color.push('#b83b22')
					hover_color.push('#c94025')
				}else if(item == 'new'){
					background_color.push('#118f99')
					hover_color.push('#13a3ae')
				}
			}
			this.chartData.labels = Object.keys(count_state)
			this.chartData.datasets[0].data = Object.values(count_state)
			this.chartData.datasets[0].backgroundColor = background_color
			this.chartData.datasets[0].hoverBackgroundColor = hover_color
		},
		sortOnKeys(dict) {
			var sorted = [];
			for(var key in dict) {
				sorted[sorted.length] = key;
			}
			sorted.sort();
			var tempDict = {};
			for(var i = 0; i < sorted.length; i++) {
				tempDict[sorted[i]] = dict[sorted[i]];
			}
			return tempDict;
		},
		filter(e, item){
			if (item.length == 0){
				return
			}
			this.$emit('clicked_value', this.chartData.labels[item[0]._index])
		}
	},
	async mounted () {
		await this.listIncidents()
		this.createGraphData(this.incidents)
		this.renderChart(this.chartData, this.options)
	}
}
</script>
<style scoped>
	#doughnut-chart{
		max-width: 200px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
</style>
