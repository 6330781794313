<template>
	<div>
		<q-item class="q-pa-none">
			<q-select
				v-model="field"
				:options="fields_local"
				label="Search by field"
				class="full-width"
			/>
		</q-item>
		<q-item v-if="field" :tag="['ATTACHMENT', 'CHECKBOX'].includes(field.field_type) ? 'label' : ''" class="q-pa-none">
			<q-select
				v-if="['SINGLESELECT', 'MULTISELECT'].includes(field.field_type)"
				v-model="field_value"
				:options="field.options"
				label="Select value"
				:option-label="(item) => item.value"
				class="full-width"
			/>
			<div v-else class="full-width flex items-center">
				<q-input
					v-if="['NUMERIC', 'EMAIL'].includes(field.field_type)"
					v-model="field_value"
					label="Input value"
					class="full-width"
					:error="has_error"
					:error-message="error_message"
					:type="field.field_type == 'EMAIL' ? 'email' : 'number'"
				/>
				<div class="text-left full-width row" v-else-if="field.field_type == 'CHECKBOX'">
					<div style="width:50%;" class="flex items-center">{{ field.label }} checked</div>
					<div style="width:50%;" class="text-right">
						<q-toggle v-model="field_value" true-value="true" false-value="false"/>
					</div>
				</div>
				<div class="text-left full-width row" v-else-if="field.field_type == 'ATTACHMENT'">
					<div style="width:50%;" class="flex items-center">{{ field.label }} has attachment</div>
					<div style="width:50%;" class="text-right">
						<q-toggle v-model="field_value" true-value="true" false-value="false"/>
					</div>
				</div>
				<q-input
					v-else-if="field.field_type == 'DATE'"
					v-model="field_value"
					class="full-width cursor-pointer"
					label='Select date'
					hint="Format DD/MM/YYYY"
					@click="show_date_modal=true"
				>
					<template v-slot:prepend>
						<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
					</template>
				</q-input>
				<!-- TIME -->
				<q-input
					v-else-if="field.field_type == 'TIME'"
					v-model="field_value"
					class="full-width cursor-pointer"
					label='Select time'
					hint="Format HH:MM:SS"
					@click="show_time_modal=true"
				>
					<template v-slot:prepend>
						<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
					</template>
				</q-input>

				<q-input
					v-else-if="field.field_type == 'DATETIME'"
					v-model="field_value"
					label='Select date and time'
					@click="date_time_dialog=true"
					hint="Format DD/MM/YYYY HH:MM:SS"
					class="cursor-pointer full-width"
				>
					<template v-slot:prepend>
						<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
					</template>
				</q-input>
				<q-input
					v-else
					v-model="field_value"
					label="Input value"
					class="full-width"
					:error="has_error"
					:error-message="error_message"
				/>
			</div>
			<div class="flex items-center justify-center" v-if="Object.keys(field).length > 0">
				<q-btn
					flat
					round
					color="dark"
					icon="o_delete"
					@click="deleteFieldValue()"
				/>
			</div>
		</q-item>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="field_value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="field_value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				style="width: 700px; max-width: 80vw"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="field_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="field_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "FilterByField",
	props: {
		index: Number,
	},
	data (){
		return {
			fields_local: [],
			field: "",
			field_value: "",
			has_error: false,
			error_message: "",
			session_settings: "",
			show_date_modal: false,
			show_time_modal: false,
			date_time_dialog: false,
		}
	},
	computed: {
		...mapGetters(["all_fields"])
	},
	methods: {
		...mapActions(["getAllFields"]),
		fetchFields(){
			this.fields_local = [];
			this.fields_local = Object.values(this.all_fields)
			this.field_value = "";
		},
		deleteFieldValue(){
			this.$emit('deleteFieldValue', this.index);
		},
	},
	watch: {
		field_value: function(){
			this.has_error = false
			this.error_message = ''
			if(this.field_value){
				let field = this.field_value
				if(typeof(this.field_value) == 'string'){
					if(this.field_value.length < 3 && this.field.field_type != 'NUMERIC'){
						this.has_error = true
						this.error_message = "You need at least 3 characters";
						return
					}
					field = {"field_id": this.field.id, "value": field};
				}
				field.label = this.field.label
				this.$emit("changeFieldValue", field, this.index);
			}
		},
		field: function(){
			if (['CHECKBOX', 'ATTACHMENT'].includes(this.field.field_type)){
				this.field_value = 'false'
			}else{
				this.field_value = ''
			}
			this.errors = "";
		}
	},
	async created(){
		await this.getAllFields();
		this.fetchFields();
	}
};
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>