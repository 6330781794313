<template>
	<div>
		<q-item class="q-pa-none">
			<q-select
				class="full-width"
				label="View by date"
				v-model="date_range"
				:options="type_of_date_ranges"
				:option-label="(item) => item.value"
			/>
		</q-item>
		<q-item
			v-if="date_range && date_range.id == 'custom'"
			class="flex justify-around wrap"
		>
			<q-date
				v-model="date_from"
				today-btn
				title="From"
				class="q-py-sm"
				@input="dateFromSelected"
				mask="DD/MM/YYYY HH:mm"
				/>
			<q-date
				v-if="date_from"
				v-model="date_to"
				today-btn
				title="To"
				mask="DD/MM/YYYY HH:mm"
				class="q-py-sm"
				:options="date_from ? limitDate : []"
				@input="dateToSelected"
			/>
		</q-item>
	</div>
</template>
<script>
export default {
	name: "FilterDate",
	data (){
		return {
			date_range: "",
			type_of_date_ranges: [],
			date_from: "",
			date_to: "",
			disabled_dates: {},
		}
	},
	methods: {
		dateFromSelected(date){
			this.date_from = date;
			this.$emit("dateFromSelected",this.date_from)

		},
		dateToSelected(date){
			this.date_to = date.split(' ')[0] + ' 23:59';
			this.$emit('dateToSelected', this.date_to)
		},
		limitDate(date){
			let date_fr = this.date_from.split(' ')[0]
			date_fr = `${date_fr.split('/')[2]}/${date_fr.split('/')[1]}/${date_fr.split('/')[0]}`
			return date >= date_fr
		},
	},
	created(){
		this.type_of_date_ranges.push({"id": "default", "value":"All date ranges"})
		this.type_of_date_ranges.push({"id": "custom", "value": "Custom dates"})
	},
	watch: {
		date_range: function(){
			if(this.date_range){
				this.date_from = "";
				this.date_to = "";
				this.$emit('resetDate', this.date_range)
			}
		},

	},
};
</script>
<style lang="scss" scoped>
	.wrap{
		flex-wrap: wrap;
	}
</style>