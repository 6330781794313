<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			{{$store.getters.module_name}} Reports
		</h1>
		<ReportsList />
	</div>
</template>

<script>
import ReportsList from "@/components/incidents/reports/ReportsList.vue";
import { mapGetters } from 'vuex';
import store from '@/store/store.js';

export default {
	name: "Reports",
	meta: {
		title: `Alert Cascade - ${store.getters.module_name} Reports`
	},
	computed: {
		...mapGetters(['module_name'])
	},
	components: {
		ReportsList
	}
};
</script>
