<template>
	<div>
		<q-list v-for="(field, index) in fields" :key="field.position">
			<FilterByField
				:index="index"
				@changeFieldValue="changeFieldValue"
				@deleteFieldValue="deleteFieldValue"
			/>
		</q-list>
		<q-btn
			v-if="$q.screen.gt.sm"
			flat
			icon="add"
			color="primary"
			label="Add new filter criteria"
			@click="addNewField()"
		/>
		<q-btn
			v-else
			flat
			icon="add"
			color="primary"
			@click="addNewField()"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Add new filter criteria
			</q-tooltip>
		</q-btn>
	</div>
</template>

<script>
import FilterByField from "@/components/incidents/reports/FilterByField.vue";
export default {
	name: "FilterByFieldList",
	components: {
		FilterByField,
	},
	data (){
		return {
			fields: [{"position": 0}],
			field_value: "",
		}
	},
	methods: {
		changeFieldValue(obj, index){
			this.field_value = obj;
			this.fields[index]['value'] = obj;
			this.$emit("changeFieldValue", this.fields);
		},
		addNewField(){
			this.fields.push({"position": this.fields.length});
		},
		deleteFieldValue(index){
			let self = this;
			if(this.fields.length > 1){
				this.fields.splice(index,1)
			}
			else{
				this.fields = [{"position": -1}],
				setTimeout(function(){
					self.$set(self.fields[0], 'position', 0)
				}, 1);
			}
			this.$emit("changeFieldValue", this.fields);
		}
	},

};
</script>
