<template>
	<div>
		<!-- GRAPH -->
		<DoughnutChart
			class="q-mb-sm"
			v-if="paginated_incidents"
			@clicked_value="filterFromGraph"
		/>

		<!-- TABLE -->
		<q-table
			v-if="paginated_incidents"
			binary-state-sort
			:columns="columns"
			:data="paginated_incidents"
			class="border-left"
			:rows-per-page-options="[5, 10, 20, 50, 0]"
			:pagination.sync="pagination"
			@request="onRequest"
		>
			<template v-slot:top>
				<div class="full-width row items-center justify-between">
					<div class="flex justify-start items-center col-12">
						<q-btn
							v-if="logged_user.customer_permission === 'ADMIN'"
							icon="filter_list" @click="filter_dialog=true"
							round
							flat
						>
							<q-tooltip anchor="top middle" :delay="500">
								Filter
							</q-tooltip>
						</q-btn>
						<q-chip v-if="!date_from && !date_to" outline color="primary">All date ranges</q-chip>
						<q-chip v-else outline color="primary" removable @remove="removeValue()">
							From {{date_from.split(' ')[0]}} to {{date_to.split(' ')[0]}}
						</q-chip>
						<div v-for="field in field_value" :key="field.value">
							<q-chip
								outline
								color="primary"
								removable
								@remove="removeValueFromList(field)"
							>
								{{field.label}} | {{field.value}}
							</q-chip>
						</div>
						<q-chip v-if="state_graph_filter" outline color="primary" removable @remove="removeValueGraph()">
							State | {{state_graph_filter}}
						</q-chip>
					</div>
					<div class="q-pt-md" :style="$q.screen.gt.sm ? 'width:47%;' : 'width:100%;'">
						<q-input
							v-model="search_by_name"
							label="Search by form name"
							outlined
							:hint="search_by_name && search_by_name	.length < 3 ? 'Enter at least 3 characters' : ''"
							@input="search_by_name.length == 0 || search_by_name.length >= 3 ? listIncidents() : ''"
						>
							<template v-slot:append>
								<q-icon name="search" />
							</template>
						</q-input>
					</div>
					<div :style="$q.screen.gt.sm ? 'width:47%;' : 'width:100%;'">
						<q-select
							v-model="form"
							:options="forms_local"
							label='Select form'
							:option-label="(item) => item.name"
							:error="has_form_error"
							:error-message="form_error_message"
							@input="changeForm"
						/>
					</div>
				</div>
			</template>
			<template v-slot:header-cell-created_at="props">
				<q-th :props="props">
					{{ props.col.label }}
					<q-tooltip anchor="top middle" :delay="500">
						{{preferred_timezone_preview}}
					</q-tooltip>
				</q-th>
			</template>
			<template v-slot:body-cell-reference_number="props">
				<q-td :props="props">
					<router-link :to="{ name: 'Edit Incident', params: {id: props.key } }" target="_blank">
						<div class="router-link-data text-center">
							{{ props.row.reference_number }}
						</div>
					</router-link>
				</q-td>
			</template>
			<template v-slot:body-cell-state="props">
				<q-td :props="props" :class="[props.row.state]">
					{{props.row.state}}
				</q-td>
			</template>
			<template v-slot:body-cell-export="props">
				<q-td :props="props">
					<q-btn
						flat
						round
						color="dark"
						icon="o_picture_as_pdf"
						@click="generatePdf(props.key)"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Download PDF
						</q-tooltip>
					</q-btn>
				</q-td>
			</template>
			<template v-slot:body-cell-name="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:body-cell-title="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:body-cell-started_by="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="pagination.page"
						boundary-links
						direction-links
						:max="number_of_pages"
						:max-pages="5"
						size="md"
						color="grey-8"
						active-color="primary"
						@click="pageNumber(pagination.page)"
					/>
				</span>
			</template>
		</q-table>

		<!-- FILTERS -->
		<q-dialog v-model="filter_dialog">
			<SimpleFilters
				@update-filters="updateFilters"
				@close="filter_dialog=false"
			/>
		</q-dialog>

		<!-- EXPORT SUMMARY -->
		<div
			v-if="logged_user.customer_permission === 'ADMIN'"
			style="position: sticky; bottom: 20px; width: fit-content; margin: 0 auto; margin-top: 8px;"
		>
			<q-btn
				v-if="$q.screen.gt.sm"
				label="Summary"
				rounded
				icon="download"
				color="primary"
				padding="md lg"
				@click="generateSummary()"
			/>
			<q-btn
				v-else
				round
				icon="download "
				color="primary"
				size="lg"
				@click="generateSummary()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Summary
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>
import DoughnutChart from '@/components/incidents/reports/DoughnatChart.vue';
import SimpleFilters from "@/components/incidents/reports/FiltersAndSort.vue";
import Pagination from "@/components/utils/Pagination.vue";
import incidentsAPI from "@/services/api/Incidents.js";
import moment from 'moment-timezone'

import { mapGetters, mapActions } from "vuex";
export default {
	name: "ReportsList",
	components: {
		DoughnutChart,
		SimpleFilters,
		Pagination,
	},
	data(){
		return {
			search_by_name: '',
			headers: ["Ref. No", "Name", "Started by", "State", "Created at"],
			pagination: {
				rowsPerPage: 20,
				rowsNumber: 0,
				descending: true,
				sortBy: 'created_at',
				page: 1,
			},
			date_from: "",
			date_to: "",
			form_id: "",
			field_value: "",
			preferred_timezone_preview: "",
			filters: {},
			state_graph_filter:'',
			filter_dialog: false,
			pending_results: false,
			columns: [
				{
					name: 'reference_number',
					field: 'reference_number',
					align: 'center',
					label: 'Ref. No',
				},
				{
					name: 'title',
					field:  'title',
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 200px; min-width: 200px;',
					headerStyle: 'max-width: 200px; min-width: 200px;',
				},
				{
					name: 'name',
					field:  'name',
					align: 'left',
					label: 'Form',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 200px; min-width: 200px;',
					headerStyle: 'max-width: 200px; min-width: 200px;',
				},
				{
					name: 'started_by',
					field: 'started_by',
					align: 'left',
					label: 'Started by',
					classes: 'ellipsis',
					style: 'max-width: 200px; min-width: 200px;',
					headerStyle: 'max-width: 200px; min-width: 200px;',
					sortable: true,
				},
				{
					name: 'state',
					field: 'state',
					align: 'left',
					label: 'State',
					sortable: true,
				},
				{
					name: 'created_at',
					field: obj => {
						return this.getDateStart(obj.created_at)
					},
					align: 'left',
					label: 'Created at',
					sortable: true,
				},
			],
			forms_local: [],
			form: "",
			has_form_error: false,
			form_error_message: ''
		}
	},
	computed: {
		...mapGetters(["paginated_incidents", "number_of_pages", "logged_user", "forms"])
	},
	methods: {
		...mapActions(['listIncidentsPagination', "listForms"]),
		async updateFilters(data){
			this.filters = {}
			this.date_from = data.date_from;
			this.date_to = data.date_to;
			this.form_id = this.form_id;
			this.field_value = data.field_value ? data.field_value.filter(value => value) : ""
			this.pagination.page = 1;
			await this.listIncidents();
		},
		async filterFromGraph(filter){
			this.state_graph_filter = filter
			await this.listIncidents()
		},
		async changeForm(){
			this.has_form_error = false
			this.form_error_message = ''
			if (!this.form){
				this.has_form_error = true
				this.form_error_message = 'Please select form'
				return
			}
			this.form_id = this.form.id
			this.pagination.page = 1
			await this.listIncidents();
		},
		async pageNumber(page_number){
			this.pagination.page = page_number
			await this.listIncidents()
		},
		async onRequest(props) {
			const { sortBy, descending, rowsPerPage, page, rowsNumber } = props.pagination
			if (descending || sortBy || rowsPerPage || page){
				this.pagination.rowsPerPage = rowsPerPage
				this.pagination.rowsNumber = rowsNumber
				this.pagination.descending = descending
				this.pagination.sortBy = sortBy
				this.pagination.page = 1
				await this.listIncidents()
			}
		},
		async listIncidents(){
			this.pending_results = true
			this.filters = {
				"page_number": this.pagination.page,
				"items_per_page": this.pagination.rowsPerPage,
				"date_from": this.date_from,
				"date_to": this.date_to,
				"is_descending": this.pagination.descending,
				"order_by": this.pagination.sortBy,
				"form_id": this.form_id,
				"field_value": this.field_value,
				"state_graph_filter": this.state_graph_filter,
				"search_by_name": this.search_by_name
			}
			this.$q.loading.show({
				delay: 400,
			})
			await this.listIncidentsPagination(this.filters);
			this.$q.loading.hide()
			this.pending_results = false
		},
		async generateSummary() {
			let response = await incidentsAPI.incidentReportSummary({"headers": this.headers, 'filters': this.filters})
			if (!response.file_id){
				this.notify('Export failed')
				return
			}
			let message = 'Your summary export was scheduled with ID: ' + response.file_id
				+ '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.notify(message)
		},
		async generatePdf(incident_id) {
			let response = await incidentsAPI.incidentGeneratePdf(incident_id, {});
			if(!response.file_id){
				this.notify('Export failed')
				return
			}
			let message = 'Your PDF export was scheduled with ID: ' + response.file_id
						+ '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.notify(message)
		},
		async removeValueFromList(field){
			let item = this.field_value.find(x => JSON.stringify(x) === JSON.stringify(field))
			let index = this.field_value.indexOf(item)
			this.field_value.splice(index, 1)
			this.pagination.page = 1
			await this.listIncidents()
		},
		async removeValue(){
			this.date_from = ""
			this.date_to = ""
			this.pagination.page = 1
			await this.listIncidents()
		},
		async removeValueGraph(){
			this.state_graph_filter = ""
			this.pagination.page = 1
			await this.listIncidents()
		},
		getDateStart(created_at){
			if (this.preferred_timezone){
				created_at = created_at.split('.')[0]
				let date_start = moment.tz(created_at, "YYYY-MM-DDTHH:mm:ss", "UTC");
				date_start = date_start.clone().tz(this.preferred_timezone)
				return date_start.format('DD/MM/YYYY HH:mm');
			}
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	async created(){
		await this.listForms();
		this.form = {"id": false, "name": "All forms"}
		this.forms_local.push({"id": false, "name": "All forms"})
		this.forms_local = this.forms_local.concat(JSON.parse(JSON.stringify(this.forms)))

		this.preferred_timezone = this.logged_user.preferred_timezone;
		this.preferred_timezone_preview = "Timezone: " + this.preferred_timezone;

		await this.listIncidents()

		if (['ADMIN', 'OPERATOR'].includes(this.logged_user.customer_permission)){
			this.columns.push(
				{
					name: 'export',
					align: 'center',
					label: 'Export',
				},
			)
		}
	},
}
</script>
<style>
	.new{ color: #118f99; }
	.active{ color: #996E00;}
	.finished{ color: #b83b22; }
</style>